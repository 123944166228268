import React from "react";
import { Container, Title, Text, DotIcon, ListBox, StyledLink } from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import BannerImages from "../BannerImgs";

const FaQ = () => {
  return (
    <>
      <Breadcrumb pageTitle="Frequently Asked Questions">
        <BreadcrumbItem
          Link="/patient-information/faq"
          Text="Frequently Asked Questions"
        />
      </Breadcrumb>
      <BannerImages imagesrc="faq" />
      <PageContainer>
        <Container>
          <Text>
            These are some of the questions we often get asked at Gippsland
            Orthodontics. We are more than happy to answer any questions you may
            have. We want to ensure that you feel comfortable about the decision
            you’re making to have orthodontic treatment.
          </Text>
          <Title>What is an orthodontist?</Title>
          <Text>
            An orthodontist is a specialised dentist who has undertaken an
            additional three years of full-time specialist training in the field
            of orthodontics. This is in addition to the five to seven years
            spent training as a general dentist. Orthodontists are specialists
            in diagnosing, preventing and treating problems relating to the
            alignment of your teeth and jaws.
          </Text>
          <Title>What is the best age to visit the orthodontist?</Title>
          <Text>
            The Australian Society of Orthodontists (ASO) recommends your child
            undergoes an orthodontic consultation by the time they are seven or
            eight years of age. This is to assess the development of their teeth
            and jaws. In many cases, no orthodontic treatment is required at
            this age, but your orthodontist will continue to review and monitor
            your child’s dental development. Early assessment with an
            orthodontist can help prevent more serious problems and
            complications. Saying that, orthodontic treatment is not just for
            children and teens! Whether you are considering treatment for
            yourself or for a child, any time is good time to visit the
            orthodontist.
          </Text>
          <Title>Do I need a referral?</Title>
          <Text>
            No, you do not need a referral to see an orthodontist. However, it
            is important to have a check up with a dentist prior to starting
            orthodontic treatment. This is to ensure a healthy mouth, teeth and
            gums prior to correcting the orthodontic concerns.
          </Text>
          <Title>How much will my orthodontic treatment cost?</Title>
          <Text>
            The cost of orthodontic treatment varies from one patient to another
            depending upon the specific concerns of each individual and the
            customised treatment plan required to treat these concerns. The only
            way to be specific in the costs for treatment is to have a
            consultation. Gippsland Orthodontics does offer payment plans with
            no interest to make treatment more affordable.{" "}
            <StyledLink to="/patient-information/payment-information">
              Further information can be found here on the payment options for
              orthodontic treatment.
            </StyledLink>
          </Text>
          <Title>Do braces hurt?</Title>
          <Text>
            Braces do not often hurt though you may feel a small amount of
            discomfort for a couple days as your teeth, gums, cheeks, and mouth
            get used to your new braces.
          </Text>
          <Title>How do I take care of my teeth during treatment?</Title>
          <Text>
            Here are some simple tricks to take care of teeth and gums during
            treatment:
          </Text>
          <ListBox>
            <DotIcon />
            <p>
              ALWAYS remember to brush your teeth after every meal (three times
              a day) and floss at least once a day
            </p>
          </ListBox>
          <ListBox>
            <DotIcon />
            <p>
              Make sure to use toothpaste that contains fluoride and ask your
              orthodontist or family dentist if you need a fluoride rinse. This
              will help prevent cavities!
            </p>
          </ListBox>
          <ListBox>
            <DotIcon />
            <p>
              During your treatment, avoid foods with a lot of sugar (sugar
              increases the amount of bacteria that grows in your mouth, causing
              more plaque and possibly cavities)
            </p>
          </ListBox>
          <ListBox>
            <DotIcon />
            <p>
              Avoid sticky and chewy foods (caramel, chewy lollies, liquorice),
              hard foods (hard lollies, nuts, ice cubes), or any foods that
              could possibly get stuck in your braces (corn on the cob, soft
              bagels, ribs, popcorn).
            </p>
          </ListBox>
          <ListBox>
            <DotIcon />
            <p>
              Be sure to schedule your routine check-ups with your family
              dentist. It is recommended that you{" "}
              <i>continue to visit the dentist every six months</i>.
            </p>
          </ListBox>
          <br />
          <Title>
            Do I have to dental check-ups with the dentist during treatment?
          </Title>
          <Text>
            Yes! In fact, it&#39;s even more important that patients receiving
            orthodontic treatment visit their dentist regularly. With braces,
            food and plaque may be caught in places that your toothbrush
            can&#39;t reach. This can lead to cavities and gum disease. Your
            dentist will work closely with your orthodontist to make sure that
            your teeth stay clean and healthy while wearing braces.
          </Text>
          <Title>
            Will my braces interfere with sports, playing an instrument or
            singing?
          </Title>
          <Text>
            Singing, playing an instrument or a contact sport may require some
            adjustment when you first get your braces. Wearing braces will not
            stop you from participating in any of your school activities! If you
            do play a contact sport, it is recommended that you wear a
            mouthguard.
          </Text>
          <Title>I still have a question</Title>
          <Text>
            We are always happy to answer any questions you may have. If your
            question is not answered here, then please{" "}
            <StyledLink to="/contact">contact our friendly team</StyledLink>.
            They will assist you in any way they can, ensuring all your
            questions are addressed.
          </Text>
        </Container>
      </PageContainer>
    </>
  );
};

export default FaQ;
